import React from "react";
import dataFeatures from "../../../data.json";

const Features = () => {
	return (
		<>
			<section
				className="p-0 text-center wow fadeInUp"
				data-wow-duration="2s"
			>
				<div className="container-fluid">
					<div className="row custom-mt-10 z-index-1 md-mt-0">
						<div className="col-md-12">
							<div
								className="owl-carousel owl-theme"
								data-dots="false"
								data-items="4"
								data-lg-items="3"
								data-md-items="2"
								data-sm-items="1"
								data-autoplay="true"
							>
								{dataFeatures.data.home[0].content.features.map(
									(feature, index) => (
										<div className="item" key={index}>
											<div className="featured-item style-2">
												<div className="featured-icon">
													<i
														className={`${feature["icon"]}`}
													></i>
													<span className="rotateme"></span>
												</div>
												<div className="featured-title">
													<h5>{feature["title"]}</h5>
												</div>
												<div className="featured-desc">
													<p>
														{feature["description"]}
													</p>
													<a
														className="icon-btn mt-4"
														href="/#"
													>
														{" "}
														<i className="la la-angle-right"></i>
													</a>
												</div>
											</div>
										</div>
									)
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default Features;
